import React from "react"
import { Link } from "gatsby"

import Page from "../templates"
import SEO from "../templates/seo"

const Danke = () => (
  <Page>
    <SEO title="Vielen Dank" />
    <h1>Kontakt</h1>
    <div
      style={{ minHeight: "25rem" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="d-flex-colum text-center">
        <h3 className="mb-4">Vielen Dank!</h3>
        <p className="mb-5">
          Deine Nachricht wurde erfolgreich zugestellt.
          <br /> Ich melde mich schnellstmöglich bei dir.
        </p>
        <Link to="/kontakt/">zurück</Link>
      </div>
    </div>
  </Page>
)

export default Danke
